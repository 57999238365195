import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col} from 'react-bootstrap';
import {graphql} from "gatsby"
import "../assets/css/section.css"
import MySection from "../components/mySection.js";
import MyCard from "../components/myCard.js";
import MyHeader from "../components/myHeader.js";
import {useI18next} from 'gatsby-plugin-react-i18next';

export const query = graphql`
query ContentQuery($page: String!) {
    allStrapiPage(filter: {name: {eq: $page}}) {
        nodes {
          header
          col1
          layout1
          col2
          layout2
          col3
          layout3
          col4
          layout4
        }
      }
    allStrapiContent (filter: {page: {name: {eq: $page}}}) {
        nodes {
            id
            name
            lang {
                tag
            }
            page {
                header
                col1
                layout1
                col2
                layout2
                col3
                layout3
                col4
                layout4
            }
            container
            title
            contentType
            text
            childStrapiContentText {
                childMdx {
                    body
                }
            }
            mediaURL
            mediaType
            mediaText
            rank
            col
            myDate
        }
    }
}
`    

const MyPage = (props) => {
    const data=props.data
    const show=props.show
    const setShow=props.setShow
    const {language} = useI18next()
    const pageNode=data.allStrapiPage.nodes[0]
    const contentNodes=data.allStrapiContent.nodes.filter((e)=>(e.lang.tag===language))
    const pageHeader = pageNode.header ? contentNodes.filter((e)=>(e.container==='header')).sort((a,b)=>( a.rank > b.rank ? 1 : -1 )) : undefined
    const col1 = contentNodes.filter((e)=>(e.col===1)&(e.container!=='carousel')&(e.container!=='header')).sort((a,b)=>( a.rank > b.rank ? 1 : -1 ))
    const col2 = contentNodes.filter((e)=>(e.col===2)&(e.container!=='carousel')&(e.container!=='header')).sort((a,b)=>( a.rank > b.rank ? 1 : -1 ))
    const col3 = contentNodes.filter((e)=>(e.col===3)&(e.container!=='carousel')&(e.container!=='header')).sort((a,b)=>( a.rank > b.rank ? 1 : -1 ))
    const col4 = contentNodes.filter((e)=>(e.col===4)&(e.container!=='carousel')&(e.container!=='header')).sort((a,b)=>( a.rank > b.rank ? 1 : -1 ))
    const cols = [col1, col2, col3, col4]
    const colsWidth = [pageNode.col1, pageNode.col2, pageNode.col3, pageNode.col4]
    const colsLayout = [pageNode.layout1, pageNode.layout2, pageNode.layout3, pageNode.layout4]
    contentNodes.forEach(node => {
        if (node.contentType==='Carousel') {
            const carouselList = contentNodes
                .filter((e)=>(e.container==='carousel')&(Math.floor(e.rank)===Math.floor(node.rank))&(e.col===node.col))
                .sort((a,b)=>( a.rank > b.rank ? 1 : -1 ))
            if (carouselList.length > 0) {
                node.carouselList=carouselList
            }
        }
    });
        
    return (
        <div>
            {!!(pageHeader) &&
                <MyHeader key={pageHeader.id} content={pageHeader} language={language}/>
            }
            <Container fluid>
                <Row>                    
                {cols.map( (col,i) => {
                    if (colsWidth[i]>0) {
                        return(
                            <Col key={i} xs={{span: 12}} md={{span: colsWidth[i]}}>
                                {!!(colsLayout[i]==='cards') && 
                                    col.map( (node, j) => {return(
                                        <MyCard key={node.id} node={node} lang={language} show={show} setShow={setShow}/>
                                        // <MyCard key={node.id} node={node} lang={language}/>
                                )})}
                                {!!(colsLayout[i]==='sections') && 
                                    col.map( (node, j) => {return(
                                        <MySection key={node.id} node={node} i={j} language={language}/>
                                )})}
                            </Col>
                        )
                    }
                    else {return(<div></div>)}
                })}
                </Row>
            </Container>    
        </div>
    )
} 

export default MyPage;