import React from "react"
import {Carousel, Image} from 'react-bootstrap';
import { MDXRenderer } from "gatsby-plugin-mdx";
import 'bootstrap/dist/css/bootstrap.min.css';
import { Container } from "react-bootstrap";
import "../assets/css/carousel.css"

const MyCarousel = (props) => {
  const contentArray = props.contentArray
  return (
    <Container>
      {!(contentArray===undefined) && 
        <Carousel>
        {contentArray.map((node) => {
          const myDateObj = new Date(node.myDate)
          const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
          const myDateFormatted = myDateObj.toLocaleDateString(node.lang.tag, options)
          return (
            <Carousel.Item key={node.id}>
              {!!(node.mediaType==='image') &&
                <Image fluid src={node.mediaURL}/> 
              }
              {!!(node.contentType==='Agenda') &&
                <h5>{myDateFormatted}</h5>
              }
              {!!(node.text!=="") &&
                <MDXRenderer>
                  {node.childStrapiContentText.childMdx.body}
                </MDXRenderer>
              }
              <br/>
            </Carousel.Item>
          )
        } )}
        </Carousel>      
      }    
    </Container>
  )
}

export default MyCarousel