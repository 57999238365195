import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Image} from 'react-bootstrap';
import { MDXRenderer } from "gatsby-plugin-mdx"
import "../assets/css/section.css"

const MySection = (props) => { 
    const node = props.node
    const i = props.i
    const sectionClass = (n) => { 
        const result = ( (n&1) ? "my-odd" : "my-even" ) + " my-section"
        return ( result ) 
    }
    const mediaSize = (node.text===null) ? {span: 10, offset: 1} : {span: 4} // pour un media sans texte réduction de 2 colonnes en largeur par rapport à valeur col2
    const textSize = (node.mediaType!==null) ? {span: 8} : {span: 12} // pour un texte sans media, largeur col2 entière pour le texte, sinon 3 colonnes pour le media 


    return (
        <Container fluid>
            <Row id={node.name} className={sectionClass(i)}>
                <Col xs={{span: 12}} md={textSize}>
                    <h3>{node.title}</h3>
                    {(node.text!=="") &&
                        <MDXRenderer>
                            {node.childStrapiContentText.childMdx.body}
                        </MDXRenderer>
                    }
                </Col>
                <Col className='my-section-media' xs={{span: 12}} md={mediaSize}>
                    {!!(node.mediaType==='video') && 
                        <video autoPlay muted controls width='100%'>
                            <source src={node.mediaURL} type="video/mp4"/>
                        </video>
                    }
                    {!!(node.mediaType==='youtube') &&
                        <iframe title={node.name} src={node.mediaURL} width='100%'>
                        </iframe>
                    }
                    {!!(node.mediaType==='image') &&
                        <Image fluid src={node.mediaURL}/> 
                    }  
                </Col>                   
            </Row>
        </Container>
    )
}

export default MySection