import React from "react";
import 'bootstrap/dist/css/bootstrap.min.css';
import {Container, Row, Col, Image} from 'react-bootstrap';
import { MDXRenderer } from "gatsby-plugin-mdx"
import MyCarousel from "../components/myCarousel.js"
import "../assets/css/section.css"
import "../assets/css/header.css"

const MyHeader = (props) => { 
    const nodes = props.content
    return (
        <Container id={nodes[0].name} fluid>
            <Row style={{position: 'absolute', top: '50px', width:'100%', zIndex: '-1', padding: '0px'}}>
                {(nodes[0].mediaType==='video') && 
                    <video autoPlay loop muted width='100%'>
                        <source src={nodes[0].mediaURL} type="video/mp4"/>
                    </video>
                }
                {(nodes[0].mediaType==='image') &&
                    <Image width='100%' src={nodes[0].mediaURL} />
                }
            </Row>
            <Row style={{paddingTop: '10%', paddingBottom: '10%', color: '#f8f8f8', textAlign: "center"}}>
                <Col xs={{span: 12}} md={{offset:1, span:4}}>
                    {(nodes[0].text!=="") &&
                        <h1>
                            <MDXRenderer>
                                {nodes[0].childStrapiContentText.childMdx.body}
                            </MDXRenderer>
                        </h1>    
                    }
                </Col> 
                {!!(nodes[1].contentType==='Carousel') &&
                    <Col xs={{span: 12}} md={{offset:1, span:4}}>
                        <MyCarousel key={nodes[1].id} contentArray={nodes[1].carouselList}/>
                    </Col>
                    }
            </Row>
        </Container>               
    )
}

export default MyHeader