import React from "react"
import {Card, Button} from 'react-bootstrap'
import {Link} from 'gatsby-plugin-react-i18next'
import { MDXRenderer } from "gatsby-plugin-mdx"
import {FaGlobe, FaSearchPlus, FaCalendarCheck, FaRegLightbulb, FaRegEnvelope} from "react-icons/fa"
import MyCarousel from "../components/myCarousel.js"
import ResponsiveEmbed from "react-responsive-embed"

const MyCard = (props) => 
{
  const node = props.node
  const setShow = props.setShow
  const myDateObj = new Date(node.myDate)
  const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' }
  const myDateFormatted = myDateObj.toLocaleDateString(props.language, options)
  const realType = (node.contentType!=='Carousel') ? node.contentType :
      (node.carouselList?.length > 0 ? node.carouselList[0].contentType : 'Agenda')   // any empty carousel defects to type 'Agenda'
  
  return (

    <Card style={{ width: '100%', marginBottom: '20px' }}>

      <Card.Header style={{textAlign: 'center'}}> 
        {!!(realType==='Agenda') && <FaCalendarCheck style={{verticalAlign: 'baseline'}}/>}
        {!!(realType==='News') && <FaGlobe style={{verticalAlign: 'baseline'}}/>}
        {!!(realType==='Focus') && <FaSearchPlus style={{verticalAlign: 'baseline'}}/>}
        {!!(realType==='Innovation') && <FaRegLightbulb style={{verticalAlign: 'baseline'}}/>}
        {!!(realType==='Contact') && <FaRegEnvelope style={{verticalAlign: 'baseline'}}/> }
        <span> {realType}</span>
        {!!(realType==='News') && <span> - {myDateFormatted}</span>}
      </Card.Header>

      {!!(node.mediaType==='video') && 
        <video autoPlay muted controls width='100%'>
          <source src={node.mediaURL} type="video/mp4"/>
          {node.mediaText}
        </video>
      }
      {!!(node.mediaType==='youtube') &&
        <ResponsiveEmbed ratio='16:9' title={node.id} src={node.mediaURL} allowFullScreen crossOrigin="anonymous"/>
      }
      {!!(node.mediaType==='image') &&
        <Card.Img variant="top" width='100%' src={node.mediaURL} alt={node.mediaText} />
      }
      {!!(node.contentType!=='Carousel') &&
        <Card.Body>
          {!!(node.title!=="") &&
            <Card.Title>{node.title}</Card.Title>
          }
          {!!(node.text!=="") &&
            <MDXRenderer>
              {node.childStrapiContentText.childMdx.body}
            </MDXRenderer>
          }
          {!!(node.contentType==='Contact') &&
            <div style={{textAlign:"center"}}>
              <Link to='#'>
                <Button onClick={() => setShow(true)}>Contact</Button>
              </Link> 
            </div>
                        
          }
        </Card.Body>  
      }
      {!!(node.contentType==='Carousel') &&
        <MyCarousel key={node.id} contentArray={node.carouselList}/>
      }
    </Card>
  )
}

export default MyCard